import * as Yup from 'yup'
import { BaseFormikConfig } from 'forms/_baseForm'
import Form from './TransactionSendLinkForm'
import { withFormik } from 'formik'
import { isEmpty } from 'lodash'

const TransactionSendLinkFormValidationSchema = Yup
    .object()
    .shape({
        type: Yup.string(),
        transaction: Yup.object().shape({
            phone: Yup
                .string()
                .test('phone_required', 'Le numéro de téléphone doit être renseigné.', function (value, context) {
                    const type = context.from[1].value.type

                    // Skip validate in case of this field doesn't shown on UI
                    if (type === 'email') return true

                    return !isEmpty(value)
                }),
            phone_iso2: Yup
                .string()
                .test('phone_iso2_required', 'Le numéro de téléphone doit être renseigné.', function (value, context) {
                    const type = context.from[1].value.type

                    // Skip validate in case of this field doesn't shown on UI
                    if (type === 'email') return true

                    return !isEmpty(value)
                }),
            email: Yup
                .string()
                .test('sms_required', 'L’E-mail doit être rempli.', function (value, context) {
                    const type = context.from[1].value.type

                    // Skip validate in case of this field doesn't shown on UI
                    if (type !== 'email') return true

                    return !isEmpty(value)
                })
        })
    })

const TransactionSendLinkFormikConfig = {
    ...BaseFormikConfig,
    enableReinitialize: true,
    mapPropsToValues: ({
        data: {
            phone,
            phone_iso2,
            email,
            payment_link_type,
        }
    }) => ({
        type: payment_link_type ?? 'sms',
        transaction: {
            phone: phone ?? '',
            phone_iso2: phone_iso2 ?? '',
            email: email ?? ''
        }
    }),
    validationSchema: TransactionSendLinkFormValidationSchema,
}

export default withFormik(TransactionSendLinkFormikConfig)(Form)
