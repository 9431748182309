import HiddenSubmitButton from 'components/HiddenSubmitButton'
import { Form, getIn, useFormikContext } from 'formik'
import userSettingsPathHelper from 'helper/pathHelper/userSettings'
import { decorateEmailString } from 'helper/stringHelper'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUserSettingQuery } from 'store/services/userSettings'
import IntlCenterPhoneField from './IntlCenterPhoneField'
import { SendTypeContainer, SendTypeEmailIcon, SendTypeFieldContainer, SendTypeSmsIcon, SendTypeWhatasppIcon, SendTypeWrapper, SendTypeEmailField, InputPhoneField } from './styled'

const TransactionSendLinkForm = ({ submitButtonRef }) => {
    const { t } = useTranslation(['transaction'])
    const { values, setFieldValue, } = useFormikContext();
    const typeName = 'type'
    const sendTypeIcons = [
        {
            icon: (selected) => <SendTypeSmsIcon selected={selected} />,
            value: 'sms'
        },
        {
            icon: (selected) => <SendTypeWhatasppIcon selected={selected} />,
            value: 'whatsapp'
        },
        {
            icon: (selected) => <SendTypeEmailIcon selected={selected} />,
            value: 'email'
        },
    ]

    const handleSendTypeChange = (value) => {
        setFieldValue(typeName, value)
    }

    const intlRef = useRef() // Just create it to make the IntlPhoneField work

    const renderSendField = () => {
        switch (getIn(values, typeName)) {
            case "sms":
            case "whatsapp":
                return (
                    <IntlCenterPhoneField
                        name='transaction.phone'
                        countryName='transaction.phone_iso2'
                        placeholder={t('phone')}
                        intlRef={intlRef}
                        variant="standard"
                    />
                )
            case "email":
                return (
                    <SendTypeEmailField
                        name={"transaction.email"}
                        placeholder={t('email')}
                        onChange={event => setFieldValue('transaction.email', decorateEmailString(event.target.value))}
                    />
                )
            default:
                break;
        }
    }

    return (
        <Form>
            <SendTypeWrapper>
                {
                    sendTypeIcons.map((sendTypeIcon) => {
                        const active = getIn(values, typeName) === sendTypeIcon.value

                        return <SendTypeContainer
                            key={sendTypeIcon.value}
                            active={active}
                            onClick={() => handleSendTypeChange(sendTypeIcon.value)}
                        >
                            {sendTypeIcon.icon(active)}
                        </SendTypeContainer>

                    })
                }
            </SendTypeWrapper>

            <SendTypeFieldContainer>
                {renderSendField()}
            </SendTypeFieldContainer>

            <HiddenSubmitButton
                ref={submitButtonRef}
            />
        </Form>
    )
}

export default TransactionSendLinkForm
